import { inject } from '@angular/core';
import {
    CanActivateFn,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChildFn,
} from '@angular/router';
import { map, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAuth } from '../model/user.auth';
import { SessionQuery } from '../state/session/session.query';

export const AuthGuardFn: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(SessionQuery);
    const router = inject(Router);
    return authService.selectUser$.pipe(
        map((data: UserAuth) => {
            if (!(data && data.accessToken)) {
                if ('' + environment.unified == 'true') {
                    if (route && route.queryParams) {
                        const { token } = route.queryParams;
                        if (!token) {
                            window.location.href = `${environment.appUrl.authUrl}auth/login?redirectUrl=${window.location.origin}`;
                        }
                    }
                }
                return true;
            }
            router.navigate(['batch/overview']);
            return false;
        }),
        catchError(() => {
            router.navigate(['auth/login']);
            return of(false);
        })
    );
};

export const AuthGuardChild: CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => AuthGuardFn(route, state);
