import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BatchState, BatchStore } from './batch.store';

@Injectable({
    providedIn: 'root',
})
export class BatchQuery extends QueryEntity<BatchState> {
    selectBatchIsLoaded$ = this.select((state) => {
        return state.isLoaded;
    });

    constructor(protected override store: BatchStore) {
        super(store);
    }
}
