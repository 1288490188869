import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Batch, BatchData, BatchError } from '../../model/batch';

export interface BatchErrorState extends EntityState<BatchError> {
    isLoaded: false;
    parentBatchId: '';
    batchData?: BatchData;
}
export function createInitialState(): BatchErrorState {
    return {
        isLoaded: false,
        parentBatchId: '',
        batchData: undefined,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'batch-error', idKey: 'id' })
export class BatchErrorStore extends EntityStore<BatchErrorState> {
    constructor() {
        super(createInitialState());
    }

    loadBatchError(
        batches: BatchErrorState[] | any,
        isLoaded: boolean,
        parentBatchId: string,
        batchData?: Batch
    ) {
        this.set(batches);
        this.update((state: any) => ({
            ...state,
            isLoaded,
            parentBatchId,
            batchData,
        }));
    }

    updateErrorBatchData(parentBatchId: string, batchData?: Batch) {
        this.update((state: any) => ({
            ...state,
            parentBatchId,
            batchData,
        }));
    }

    updatePost(batchError: BatchErrorState | any) {
        this.update(batchError.id, JSON.parse(JSON.stringify(batchError)));
    }
}
