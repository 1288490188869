import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BatchErrorState, BatchErrorStore } from './batch.errors.store';

@Injectable({
    providedIn: 'root',
})
export class BatchErrorQuery extends QueryEntity<BatchErrorState> {
    selectBatchIsLoaded$ = this.select((state) => {
        return state.isLoaded;
    });

    selectBatchParentIdLoaded$ = this.select((state) => {
        return state.parentBatchId;
    });

    selectBatchData$ = this.select((state) => {
        return state.batchData;
    });

    constructor(protected override store: BatchErrorStore) {
        super(store);
    }
}
