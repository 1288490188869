<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col-md-2 side-content">
            <app-sidebar
                [sideBarMenu]="sideBarMenu"
                [userInfo]="userLoginInfo$ | async"
            ></app-sidebar>
        </div>
        <div class="col-md main-content">
            <div class="p-57">
                <app-header
                    [backUrl]="backUrl"
                    [headerOptions]="headerOptions"
                ></app-header>
                <app-header-info
                    *ngIf="isEnableHeaderInfo"
                    [batchInfo]="batchInfo$ | async"
                    [displayType]="displayType"
                    [userInfo]="userLoginInfo$ | async"
                ></app-header-info>
            </div>
            <div class="page-wrapper" [ngClass]="classWrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-right" key="br"></p-toast>
<p-toast position="bottom-left" key="bl"></p-toast>
