import { Component, Input } from '@angular/core';
import { Batch } from 'src/app/core/model/batch';
import { UserAuth } from 'src/app/core/model/user.auth';
import { LabelHelper } from '../../helper/label.helper';
import { TrimTextPipe } from '../../pipes/trim.text.pipe';

@Component({
    selector: 'app-header-info',
    templateUrl: './header-info.component.html',
    styleUrls: ['./header-info.component.scss'],
})
export class HeaderInfoComponent {
    headerUserInfo: HeaderInfo[] = [];
    otherInfo: HeaderInfo[] = [];
    headerBatchInfo: HeaderInfo[] = [];
    batchStatus!: HeaderInfo;

    @Input()
    displayType: any | 'user' | 'other' | 'batch-info' = 'user';

    constructor(
        private labelHelper: LabelHelper,
        private trimText: TrimTextPipe
    ) { }

    private _userInfo!: UserAuth;
    @Input()
    set userInfo(userAuth: UserAuth | any) {
        this.headerUserInfo = [];
        if (userAuth) {
            const customerName: HeaderInfo = {
                title: 'Customer Name',
                value: userAuth.groupName ? userAuth.groupName : userAuth.displayName,
            };
            const customerIDValue = userAuth.userExtractedToken && userAuth.userExtractedToken.user_id
                ? userAuth.userExtractedToken.user_id : '';
            const customerId: HeaderInfo = {
                title: 'Customer ID',
                value: userAuth.groupId ? this.trimText.transform(userAuth.groupId) : this.trimText.transform(customerIDValue),
            };
            this.headerUserInfo.push(customerName);
            this.headerUserInfo.push(customerId);
        }
    }

    get userInfo(): UserAuth {
        return this._userInfo;
    }

    private _batchInfo!: Batch;
    @Input()
    set batchInfo(batch: Batch | any) {
        this.headerBatchInfo = [];
        if (batch) {
            const batchStatus: HeaderInfo = {
                title: '',
                value: batch.status,
                type: 'status',
            };
            const batchId: HeaderInfo = {
                title: 'ID',
                value: this.trimText.transform(batch.batchId ? batch.batchId : ''),
                className: 'mw-300'
            };
            const batchServices: HeaderInfo = {
                title: 'Data Services',
                value: batch.batchServiceName,
                className: 'mw-50per'
            };

            const requestedDateTime: HeaderInfo = {
                title: 'Requested',
                value: batch.requestedDatetime,
                type: 'date',
                className: 'mw-300'
            };
            const reference: HeaderInfo = {
                title: 'Reference',
                value: batch.userBatchRef,
                className: 'mw-50per'
            };
            const rows: HeaderInfo = {
                title: 'Rows',
                value: batch.rowsInBatch,
                className: 'mw-300'
            };
            const error: HeaderInfo = {
                title: 'Errors',
                value: batch.totalErrors,
                className: 'mw-300'
            };
            this.batchStatus = batchStatus;

            this.headerBatchInfo.push(batchId);

            this.headerBatchInfo.push(requestedDateTime);
            this.headerBatchInfo.push(batchServices);
            this.headerBatchInfo.push(rows);
            this.headerBatchInfo.push(error);
            this.headerBatchInfo.push(reference);

            this._batchInfo = batch;
        }
    }

    get batch(): Batch {
        return this._batchInfo;
    }

    @Input()
    otherData!: HeaderInfo[];
}

export interface HeaderInfo {
    title: string;
    value: any;
    type?: 'status' | any;
    className?: string | any;
}
