import { Component, OnInit } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { environment } from 'src/environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(private themeService: ThemeService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.themeService.switchTheme(environment.country.toLowerCase());
        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(async (data) => {
            if ('token' in data) {
                const { token } = data;
                const result = await lastValueFrom(this.authService.signInWithToken(token).pipe(untilDestroyed(this)));
                if (result != null) {
                    setTimeout(() => {
                        this.router.navigate(['/dashboard']);
                    }, 500);
                } else {
                    window.location.href = `${environment.appUrl.authUrl}logout?logout=true`;
                }
            }
        });

    }
}
