import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { BatchOverviewComponent } from './batches/batch-overview/batch-overview.component';
import { BatchAddComponent } from './batches/batch-add/batch-add.component';
import { BatchGuardChild } from 'src/app/core/guard/batch.guard';
import { SharedPrimeNgModule } from 'src/app/shared/shared-prime-ng.module';
import { BatchErrorComponent } from './batches/batch-error/batch-error.component';
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/batch/overview',
        pathMatch: 'full',
    },
    {
        path: 'batch',
        component: DashboardComponent,
        canActivateChild: [BatchGuardChild],
        children: [
            {
                path: 'overview',
                component: BatchOverviewComponent,
            },
            {
                path: 'error/:batchId',
                component: BatchErrorComponent,
            },
            {
                path: 'add',
                component: BatchAddComponent,
            },
        ],
    },
];

@NgModule({
    declarations: [
        DashboardComponent,
        BatchOverviewComponent,
        BatchAddComponent,
        BatchErrorComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedPrimeNgModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        SharedModule,
    ],
    providers: [],
})
export class DashboardModule { }
