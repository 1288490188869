<div class="header-wrapper">
    <div class="row">
        <div class="col-md-6">
            <h1 *ngIf="!backUrl">
                <span class="{{ headerOptions.icon }}"></span>
                {{ headerOptions.title }}
            </h1>
            <div class="pointer backBtn" *ngIf="backUrl && backUrl.hasBackurl" (click)="goBackToUrl(backUrl.url)">
                <span class="icon icon-back"></span>
                Back
            </div>
        </div>
        <div class="col-md-6 text-right">
            <button pButton class="p-element p-ripple p-button-text p-button p-component" (click)="signOut()">
                Logout
            </button>
        </div>
    </div>
</div>