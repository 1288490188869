<div class="form-auth-wrapper container">
    <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
        <div class="text-center">
            <app-logo></app-logo>
            <h2 class="mt-86">Welcome back</h2>
            <p class="mp-142"></p>
        </div>
        <br />
        <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="login">User Name</label>
                        <input type="text" id="login" pInputText formControlName="login"
                            placeholder="Enter your Email" />
                    </span>
                </div>
                <div [hidden]="
                        loginForm.controls['login'].valid ||
                        !loginForm.controls['login'].dirty
                    " class="error-message">
                    <ng-container
                        *ngIf="loginForm.controls['login'].errors && !loginForm.controls['login'].errors['incorrect']">
                        Username is incorrect
                    </ng-container>

                </div>
            </div>
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="password">Password</label>
                        <input type="password" id="password" pInputText formControlName="password"
                            placeholder="******" />
                    </span>
                </div>
                <div [hidden]="
                        loginForm.controls['password'].valid ||
                        !loginForm.controls['password'].dirty
                    " class="error-message">

                    <ng-container
                        *ngIf="loginForm.controls['password'].errors && !loginForm.controls['password'].errors['incorrect']">
                        Password is incorrect
                    </ng-container>
                </div>
            </div>
            <button pButton type="submit" class="mt-11 p-element w-100 p-ripple p-button-raised p-button p-component"
                label="Sign in" [disabled]="
                    !loginForm.touched ||
                    (!loginForm.valid && loginForm.touched) ||
                    (isLoginLoading$ | async)
                "></button>
        </div>
    </form>
</div>

<div class="no-account">
    Don't Have an account?
    <a href="mailto: {{
            country === 'NZ'
                ? 'support@motorweb.co.nz'
                : ' support@motorweb.com.au'
        }}">
        Get in contact</a>
</div>