import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { catchError, Observable, of, tap } from 'rxjs';
import { FileHelper } from 'src/app/shared/helper/file.helper';
import { environment } from 'src/environments/environment';
import {
    BATCH_SERVICES_URL,
    BATCH_TYPES_URL,
} from '../constants/batch.urls.constant';
import { Batch, BatchTypes } from '../model/batch';
import { BatchServiceStore } from '../state/batches/batch.services.store';
import { BatchTypesStore } from '../state/batches/batch.types.store';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class BatchToolsService {
    constructor(
        private http: HttpClient,
        private batchServicesStore: BatchServiceStore,
        private batchTypesStore: BatchTypesStore,
        private messageService: MessageService,
        private fileHelper: FileHelper
    ) { }

    /**
     *
     * @param country pass country for user under
     * @returns list of batch services
     */
    getBatchServices(country?: string | any): Observable<BatchTypes[]> {
        const params = country ? '?country=' + country : '';
        return this.http
            .get<Batch[]>(
                `${environment.apiUrl}` + `${BATCH_SERVICES_URL}` + params
            )
            .pipe(
                untilDestroyed(this),
                tap((batchServices: any) => {
                    if (batchServices) {
                        const { batchServiceResponses } = batchServices;
                        this.batchServicesStore.loadBatchServices(
                            batchServiceResponses,
                            true
                        );
                        return batchServices;
                    }
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message,
                        closable: true,
                    });
                    this.batchServicesStore.setLoading(false);
                    return of(e);
                })
            );
    }

    /**
     *
     * @param country pass date
     * @returns list of batch types
     */
    getBatchTypes(country?: string | any): Observable<Batch[]> {
        const params = country ? '?country=' + country : '';

        return this.http
            .get<BatchTypes[]>(
                `${environment.apiUrl}` + `${BATCH_TYPES_URL}` + params
            )
            .pipe(
                untilDestroyed(this),
                tap((batchResTypes: any) => {
                    if (batchResTypes) {
                        const { batchTypes } = batchResTypes;
                        this.batchTypesStore.loadBatchTypes(batchTypes, true);
                        return batchTypes;
                    }
                    this.batchTypesStore.setLoading(false);
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message,
                        closable: true,
                    });
                    this.batchTypesStore.setLoading(false);
                    return of(e);
                })
            );
    }

    /**
     *
     * @param batchServiceId
     * @returns download batch template csv
     */
    downloadBatchTemplate(batchServiceId?: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            }),
            responseType: 'arraybuffer' as any,
        };
        return this.http
            .get<any>(
                `${environment.apiUrl}` +
                `${BATCH_SERVICES_URL}` +
                `/${batchServiceId}/template`,
                httpOptions
            )
            .pipe(
                tap((res: any) => {
                    const blob = new Blob([res], { type: 'text/csv' });
                    saveAs.saveAs(
                        blob,
                        batchServiceId + '_batch_template.csv'
                    );
                    return res;
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message,
                        closable: false,
                    });
                    return of(e);
                })
            );
    }
}
