<div class="flex-info-wrapper row">
    <ng-container [ngSwitch]="displayType">
        <ng-container *ngSwitchCase="'user'">
            <ng-container *ngFor="let h of headerUserInfo">
                <div class="flex-info col-md">
                    <div class="flex-title">
                        {{ h.title }}
                    </div>
                    <div class="flex-label">
                        {{ h.value }}
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'batch-info'">

            <div class="flex-info col-md status-wrapper-size" *ngIf="batchStatus">
                <div class="status-wrapper">
                    <span class="{{ batchStatus.value | statusIcon }}"></span>
                    <p>{{ batchStatus.value }}</p>
                </div>
            </div>
            <div class="flex-info flex-max col-md-10">
                <div class="row">
                    <ng-container *ngFor="let h of headerBatchInfo">
                        <ng-container [ngSwitch]="h.type">
                            <ng-container *ngSwitchCase="'status'">
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <div class="flex-child col-md-4" ngClass="{{h.className}}">
                                    <div class="flex-title">
                                        {{ h.title }}
                                    </div>
                                    <div class="flex-label">
                                        {{
                                        (h.value | dateOrEmpty)
                                        ? (h.value | date : 'dd/MM/yyyy')
                                        : 'Invalid Date'
                                        }}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <div class="flex-child col-md-4" ngClass="{{h.className}}">
                                    <div class="flex-title">
                                        {{ h.title }}
                                    </div>
                                    <div class="flex-label">
                                        {{ h.value }}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>

            </div>

        </ng-container>
    </ng-container>
</div>