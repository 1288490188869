import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BatchTypesStore, BatchTypeState } from './batch.types.store';

@Injectable({
    providedIn: 'root',
})
export class BatchTypesQuery extends QueryEntity<BatchTypeState> {
    selectBatchTypeIsLoaded$ = this.select((state) => {
        return state.isLoadedType;
    });

    constructor(protected override store: BatchTypesStore) {
        super(store);
    }
}
