import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MessageService } from 'primeng/api';
import { finalize, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { Batch } from 'src/app/core/model/batch';
import { BATCH_STATUS } from 'src/app/core/constants/batch.constant';
import { BatchService } from 'src/app/core/services/batch.service';
import { BatchQuery } from 'src/app/core/state/batches/batch.query';
import { BatchStore } from 'src/app/core/state/batches/batch.store';
import {
    TableActionEvent,
    TableColumns,
} from 'src/app/shared/components/dynamic-table/dynamic-table.component';
import { DateHelper } from 'src/app/shared/helper/date.helper';

@UntilDestroy()
@Component({
    selector: 'app-batch-overview',
    styleUrls: ['./batch-overview.component.scss'],
    templateUrl: './batch-overview.component.html',
})
export class BatchOverviewComponent implements OnInit, OnDestroy {
    title: string = 'All Requests';
    currentSidePanel: string | 'cancel-panel' = '';
    currentSelectedData: Batch | any = null;
    isSidePanelVisible: boolean = false;
    batchData$: Observable<Batch[]> = this.batchQuery.selectAll();
    batchDataIsLoading$: Observable<boolean> = this.batchQuery.selectLoading();
    loader = this.loadingBar.useRef();
    reloadBatches: any;
    columnTable: TableColumns[] = [
        {
            field: 'batchId',
            header: 'ID',
            type: 'id',
            styleCss: 'width: 100px;',
        },
        {
            field: 'batchServiceName',
            header: 'Data Service',
            type: 'text',
            styleCss: 'width: 120px;',
            styleClass: 'mw-mobile-80'
        },
        {
            field: 'requestedDatetime',
            header: 'Requested',
            type: 'date',
            styleCss: 'width: 200px;',
        },
        {
            field: 'userBatchRef',
            header: 'Reference',
            type: 'text',
            styleCss: 'width: 10vw',
            styleClass: 'mw-mobile-100'
        },
        {
            field: 'rowsProcessed',
            header: 'Progress',
            type: 'progress-bar',
            combineFields: ['rowsProcessed', 'rowsInBatch'],
            styleCss: 'width: 5vw;',
        },
        {
            field: 'totalErrors',
            header: 'Errors',
            type: 'errors',
            combineFields: ['rowsProcessed', 'rowsInBatch'],
            styleCss: 'width: 150px;',
            hideFromField: {
                field: 'status',
                values: [
                    BATCH_STATUS.CANCELLED,
                    BATCH_STATUS.PREPROCESSING,
                    BATCH_STATUS.FAILED,
                    BATCH_STATUS.SCHEDULED,
                    BATCH_STATUS.EXPIRED,
                    BATCH_STATUS.PROCESSING,
                ],
            },
            hideIfValueField: {
                field: 'processingErrors',
                value: 0,
                condition: 'greater',
            },
        },
        {
            field: 'status',
            header: 'Status',
            type: 'status',
            styleCss: 'width: 100px;text-align: center;',
            displayFieldValue: 'requestedDatetime',
        },
        {
            field: 'batchId',
            header: 'Export',
            type: 'action-icon',
            actionIcon: 'icon icon-download',
            styleCss: 'width: 50px;text-align: center;',
            action: 'download',
            disabledFromField: {
                field: 'status',
                values: [
                    BATCH_STATUS.CANCELLED,
                    BATCH_STATUS.PREPROCESSING,
                    BATCH_STATUS.FAILED,
                    BATCH_STATUS.SCHEDULED,
                    BATCH_STATUS.EXPIRED,
                    BATCH_STATUS.PROCESSING,
                ],
            },
        },
        {
            field: 'batchId',
            header: 'Cancel',
            type: 'action-icon',
            actionIcon: 'icon icon-remove',
            styleCss: 'width: 50px;',
            action: 'remove',
            disabledFromField: {
                field: 'status',
                values: [
                    BATCH_STATUS.COMPLETED,
                    BATCH_STATUS.CANCELLED,
                    BATCH_STATUS.FAILED,
                    BATCH_STATUS.PROCESSING,
                    BATCH_STATUS.PREPROCESSING
                ],
            },
        },
    ];

    constructor(
        private router: Router,
        private batchService: BatchService,
        private batchStore: BatchStore,
        private batchQuery: BatchQuery,
        private loadingBar: LoadingBarService,
        private dateHelper: DateHelper,
        private messageService: MessageService
    ) { }

    ngOnDestroy() {
        clearInterval(this.reloadBatches);
    }

    ngOnInit() {
        this.batchStore.setLoading(true);
        this.reloadBatches = setInterval(() => {
            this.batchStore.updateLoaded(false);
        }, 15000);
        this.batchQuery.selectBatchIsLoaded$
            .pipe(
                switchMap((isLoaded) => {
                    if (!isLoaded) {
                        this.batchStore.setLoading(true);
                        const dateToday = this.dateHelper
                            .getMinusDateToday(3)
                            .toISOString();
                        return this.batchService.getBatches(dateToday);
                    }
                    return of(undefined);
                }),
                untilDestroyed(this),
                finalize(() => {
                    // This block will be executed after the observable completes
                    // whether it was successful or had an error
                    this.batchStore.setLoading(false);
                })
            )
            .subscribe(() => this.batchStore.setLoading(false));
    }

    actionEvents(action: TableActionEvent) {
        switch (action.action) {
        case 'remove':
            this.isSidePanelVisible = true;
            this.currentSidePanel = 'cancel-panel';
            this.currentSelectedData = action.rowData;
            break;
        case 'download':
            this.currentSelectedData = action.rowData;
            this.downloadBatch();
            break;
        case 'error':
            this.currentSelectedData = action.rowData;
            this.goToBatchErrorPage();
            break;
        default:
            break;
        }
    }

    goToAddBatch() {
        this.router.navigate(['batch/add']);
    }

    onHideEvent(value: any) {
        this.isSidePanelVisible = value;
    }

    async cancelBatch() {
        this.batchStore.setLoading(true);
        await lastValueFrom(
            this.batchService
                .deleteBatch(this.currentSelectedData.batchId)
                .pipe(untilDestroyed(this))
        );
        this.isSidePanelVisible = false;
    }

    async downloadBatch() {
        this.loader.start();
        this.messageService.add({
            key: 'br',
            severity: 'info',
            summary: 'Downloading ',
            detail: 'Downloading batch id ' + this.currentSelectedData.batchId,
            closable: false,
        });
        await lastValueFrom(
            this.batchService
                .downloadBatchReport(this.currentSelectedData.batchId)
                .pipe(untilDestroyed(this))
        );
        this.loader.complete();
    }

    async goToBatchErrorPage() {
        this.router.navigate([
            'batch/error/',
            this.currentSelectedData.batchId,
        ]);
    }
}
