import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom, Observable, of, switchMap, zip } from 'rxjs';
import { Batch } from 'src/app/core/model/batch';
import { BatchToolsService } from 'src/app/core/services/batch.tools.service';
import { BatchErrorQuery } from 'src/app/core/state/batches/batch.errors.query';
import { BatchServicesQuery } from 'src/app/core/state/batches/batch.services.query';
import { BatchServiceStore } from 'src/app/core/state/batches/batch.services.store';
import { BatchTypesQuery } from 'src/app/core/state/batches/batch.types.query';
import { BatchTypesStore } from 'src/app/core/state/batches/batch.types.store';
import { SessionQuery } from 'src/app/core/state/session/session.query';
import {
    BackUrl,
    HeaderOptions,
} from 'src/app/shared/components/header/header.component';
import { SideBarMenu } from 'src/app/shared/components/sidebar/sidebar.component';

@UntilDestroy()
@Component({
    selector: 'app-dashboard',
    styleUrls: ['./dashboard.component.scss'],
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    userLoginInfo$ = this.sessionQuery.select();
    batchInfo$: Observable<Batch> | undefined | any;
    backUrl: BackUrl | undefined = undefined;
    isEnableHeaderInfo: boolean = true;
    classWrapper = '';
    sideBarMenu: SideBarMenu[] = [
        {
            label: 'Bulk Data Overview',
            url: '/batch/overview',
            iconClass: 'icon icon-menu',
            isActive: true,
        },
        {
            label: 'Add a Request',
            url: '/batch/add',
            iconClass: 'icon icon-plus',
            isActive: false,
        },
    ];
    displayType = 'user';

    headerOptions: HeaderOptions = {
        title: 'Bulk Data Overview',
        icon: 'icon icon-menu',
    };

    constructor(
        private router: Router,
        private sessionQuery: SessionQuery,
        private batchErrorQuery: BatchErrorQuery,
        private batchServiceQuery: BatchServicesQuery,
        private batchServiceStore: BatchServiceStore,
        private batchToolsService: BatchToolsService,
        private batchTypesQuery: BatchTypesQuery,
        private batchTypesStore: BatchTypesStore
    ) { }

    ngOnInit(): void {
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            const foundNavigationEnd = event instanceof NavigationEnd || (event && 'routerEvent' in event && event['routerEvent'].url);
            if (foundNavigationEnd && this.router.url) {
                this.backUrl = undefined;
                this.displayType = 'user';
                this.classWrapper = '';
                this.sideBarMenu = this.sideBarMenu.map(
                    (data: SideBarMenu) => {
                        data.isActive = this.router.url.startsWith(
                            data.url
                        );
                        this.isEnableHeaderInfo = true;
                        if (data.isActive) {
                            this.headerOptions.icon = data.iconClass;
                            this.headerOptions.title = data.label;
                        }
                        if (
                            data.url.startsWith('/batch/overview') &&
                            this.router.url.startsWith('/batch/error')
                        ) {
                            data.isActive = true;
                            this.backUrl = {
                                hasBackurl: true,
                                url: '/batch/overview',
                            };
                            this.displayType = 'batch-info';
                        }
                        if (this.router.url.startsWith('/batch/add')) {
                            this.isEnableHeaderInfo = false;
                            this.classWrapper = 'batch-add-wrapper';
                        }
                        return data;
                    }
                );
            }

        });

        this.batchInfo$ = this.batchErrorQuery.selectBatchData$;

        zip(
            this.userLoginInfo$,
            this.batchServiceQuery.selectBatchServiceIsLoaded$,
            this.batchTypesQuery.selectBatchTypeIsLoaded$
        )
            .pipe(
                untilDestroyed(this),
                switchMap(async (data) => {
                    const [user, isLoaded, isLoadedType] = data;
                    if (!isLoaded && user) {
                        this.batchServiceStore.setLoading(true);
                        await lastValueFrom(
                            this.batchToolsService.getBatchServices(
                                user.userExtractedToken?.ctry
                            )
                        );
                    }
                    if (!isLoadedType && user) {
                        this.batchTypesStore.setLoading(true);
                        await lastValueFrom(
                            this.batchToolsService.getBatchTypes('')
                        );
                    }
                    return of(undefined);
                })
            )
            .subscribe(() => this.batchServiceStore.setLoading(false));
    }
}
