<ng-container *ngIf="tableData">
    <p-table [columns]="tableData.cols" [value]="tableData.data" [loading]="isLoading" [scrollable]="true"
        [loadingIcon]="'pi pi-spin pi-spinner'" scrollHeight="{{ scrollHeight }}" [virtualScroll]="true"
        [virtualScrollItemSize]="100">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngFor="let col of tableData.cols">
                    <th [style]="col.styleCss" ngClass={{col.styleClass}}>{{ col.header }}</th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [colSpan]="tableData.cols.length">
                    <div class="text-center">No records found.</div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="loadingBody" let-rowData let-columns="columns">
            <tr style="height: 46px">
                <td *ngFor="let col of columns; let even = even">
                    <p-skeleton [ngStyle]="{
                            width: even
                                ? col.field === 'year'
                                    ? '30%'
                                    : '40%'
                                : '60%'
                        }"></p-skeleton>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <ng-container *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.type">
                        <td *ngSwitchCase="'progress-bar'">
                            <p>
                                {{ rowData[col.combineFields[0]] }} /
                                {{ rowData[col.combineFields[1]] }}
                            </p>
                            <div>
                                <p-progressBar [value]="
                                        (rowData[col.combineFields[0]] * 100) /
                                        rowData[col.combineFields[1]]
                                    " [showValue]="false" [style]="{ height: '3px' }">
                                </p-progressBar>
                            </div>
                        </td>
                        <td *ngSwitchCase="'date'">
                            {{
                            (rowData[col.field] | dateOrEmpty)
                            ? (rowData[col.field] | date : 'dd/MM/yyyy')
                            : 'Invalid Date'
                            }}
                        </td>
                        <td *ngSwitchCase="'id'">
                            <div class='mw-100'>{{ rowData[col.field] | trimText }}</div>
                        </td>
                        <td *ngSwitchCase="'errors'">
                            <ng-container *ngIf="rowData[col.field] && rowData[col.field] > 0 ">
                                <div class="error" (click)="onActionEvent(rowData, 'error')">
                                    Errors
                                </div>
                            </ng-container>
                        </td>
                        <td *ngSwitchCase="'action-icon'">
                            <div class="action-icon" [class.disabled]="
                                    col.disabledFromField &&
                                    rowData[col.disabledFromField.field] &&
                                    col.disabledFromField.values.includes(
                                        rowData[col.disabledFromField.field]
                                    )
                                " (click)="
                                    col.disabledFromField &&
                                    rowData[col.disabledFromField.field] &&
                                    col.disabledFromField.values.includes(
                                        rowData[col.disabledFromField.field]
                                    )
                                        ? {}
                                        : onActionEvent(rowData, col.action)
                                ">
                                <span class="{{ col.actionIcon }}"> </span>
                            </div>
                        </td>

                        <td *ngSwitchCase="'status'">
                            <div class="status-wrapper" pTooltip="{{
                                    rowData[col.field]
                                        | statusIcon
                                            : 'tooltip'
                                            : ((rowData[col.displayFieldValue]
                                              | dateOrEmpty)
                                                  ? (rowData[
                                                        col.displayFieldValue
                                                    ] | date : 'dd/MM/yyyy')
                                                  : '')
                                }}" tooltipPosition="top">
                                <span class="{{
                                        rowData[col.field] | statusIcon
                                    }}"></span>
                                <p>
                                    {{
                                    ( rowData[col.field]
                                    | statusIcon : 'label' ).toLowerCase()
                                    }}
                                </p>
                            </div>
                        </td>
                        <td *ngSwitchDefault>
                            <div class="wp-break" [style]="col.styleCss" ngClass={{col.styleClass}}> {{
                                rowData[col.field] }}</div>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</ng-container>