import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BatchSvc } from '../../model/batch.svc';

export interface BatchServiceState extends EntityState<BatchSvc> {
    isLoaded: false;
}
export function createInitialState(): BatchServiceState {
    return {
        isLoaded: false,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'batch-service', idKey: 'batchServiceId' })
export class BatchServiceStore extends EntityStore<BatchServiceState> {
    constructor() {
        super(createInitialState());
    }

    loadBatchServices(batches: BatchSvc[] | any, isLoaded: boolean) {
        this.set(batches);
        this.update((state: any) => ({
            ...state,
            isLoaded,
        }));
    }

    updatePost(batch: BatchSvc | any) {
        this.update(batch.id, JSON.parse(JSON.stringify(batch)));
    }
}
