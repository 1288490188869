import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SharedPrimeNgModule } from './shared-prime-ng.module';
import { LogoComponent } from './components/logo/logo.component';
import { FormsModule } from '@angular/forms';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DateOrEmptyPipe } from './pipes/dateOrEmpty.pipe';
import { HeaderInfoComponent } from './components/header-info/header-info.component';
import { StatusIconPipe } from './pipes/status.icon.pipe';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { TrimTextPipe } from './pipes/trim.text.pipe';

@NgModule({
    declarations: [
        HeaderComponent,
        SidebarComponent,
        DynamicTableComponent,
        HeaderInfoComponent,
        SidePanelComponent,

        DateOrEmptyPipe,
        StatusIconPipe,
        TrimTextPipe,
    ],
    imports: [CommonModule, FormsModule, SharedPrimeNgModule, LogoComponent],
    exports: [
        HeaderComponent,
        SidebarComponent,
        DynamicTableComponent,
        HeaderInfoComponent,
        SidePanelComponent,

        DateOrEmptyPipe,
        StatusIconPipe,
        TrimTextPipe,
    ],
    providers: [TrimTextPipe],
})
export class SharedModule {}
