import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { MessageService } from 'primeng/api';
import { lastValueFrom, Observable } from 'rxjs';
import { BatchForm, BatchTypes } from 'src/app/core/model/batch';
import { BatchSvc } from 'src/app/core/model/batch.svc';
import { BatchService } from 'src/app/core/services/batch.service';
import { BatchToolsService } from 'src/app/core/services/batch.tools.service';
import { BatchServicesQuery } from 'src/app/core/state/batches/batch.services.query';
import { BatchStore } from 'src/app/core/state/batches/batch.store';
import { BatchTypesQuery } from 'src/app/core/state/batches/batch.types.query';

@Component({
    selector: 'app-batch-add',
    templateUrl: './batch-add.component.html',
    styleUrls: ['./batch-add.component.scss'],
})
export class BatchAddComponent {
    @ViewChild('pUpload') pUpload: any;

    batchServiceData$: Observable<BatchSvc[] | any> =
        this.batchServicesQuery.selectAll();
    batchTypeData$: Observable<BatchTypes[] | any> =
        this.batchTypesQuery.selectAll();
    uploadedFiles: any[] = [];
    batchAddForm: FormGroup = new FormGroup({
        userBatchReference: new FormControl<string>(''),
        email: new FormControl<string>('', [
            Validators.required,
            Validators.email,
        ]),
        batchType: new FormControl<string>('', [Validators.required]),
        scheduledDate: new FormControl<Date>(new Date()),
    });
    isBatchCreateLoading: boolean = false;
    fileUuid: string | undefined;

    constructor(
        private batchToolService: BatchToolsService,
        private batchService: BatchService,
        private batchServicesQuery: BatchServicesQuery,
        private batchTypesQuery: BatchTypesQuery,
        private router: Router,
        private messageService: MessageService,
        private batchStore: BatchStore
    ) { }

    async onSubmit() {
        const { email, userBatchReference, batchType } =
            this.batchAddForm.value;
        const batchAddForm: BatchForm = {
            email,
            scheduledDate: new Date(),
            userBatchReference,
            batchServiceId: batchType.batchServiceId,
            batchType: batchType.serviceMapping,
            file: this.uploadedFiles[0],
        };
        this.isBatchCreateLoading = true;
        const res = await lastValueFrom(this.batchService.createBatch(this.fileUuid as string, batchAddForm));
        if (res && !res.error) {
            this.messageService.add({
                key: 'br',
                severity: 'primary',
                summary: 'Success',
                detail: 'Batch created succesfully',
                closable: false,
            });
            this.batchAddForm.reset();
            this.batchStore.updateLoaded(false);
            setTimeout(() => {
                this.router.navigate(['/batch/overview']);
            }, 100);
        } else {
            this.removeFile();
        }
        this.isBatchCreateLoading = false;

    }

    onSelect($event: any) {
        if ($event) {
            this.fileUuid = uuid();
            this.uploadedFiles = [];
            this.uploadedFiles = [...$event.currentFiles];
            
            if (!this.uploadedFiles.length) {
                const [file] = $event.files;
                if (file && file.type != 'text/csv') {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Incorrect file format.',
                        closable: false,
                    });
                } else {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'File is bigger than 500MB.',
                        closable: false,
                    });
                }
            }
        }
    }

    async downloadTemplate() {
        const { batchType } = this.batchAddForm.value;
        if (batchType) {
            await lastValueFrom(
                this.batchToolService.downloadBatchTemplate(
                    batchType.batchServiceId
                )
            );
        } else {
            this.messageService.add({
                key: 'br',
                severity: 'error',
                summary: 'Error',
                detail: 'Please choose batch service',
                closable: false,
            });
        }
    }

    removeFile() {
        this.pUpload.clear();
        this.uploadedFiles = [];
    }

    navigateOverview() {
        this.router.navigate(['/batch/overview']);
    }
}
