import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import jwt_decode from 'jwt-decode';
import { lastValueFrom, take } from 'rxjs';
import { SessionStore } from '../state/session/session.store';
import { AuthService } from './auth.service';

@Injectable()
export class AppInitializerService {
    constructor(
    private authService: AuthService,
    private sessionStore: SessionStore,
    private afAuth: AngularFireAuth,
    ) {}

    async initializeApp(): Promise<void | any> {
        try {
            const authState: any = await lastValueFrom(this.afAuth.authState.pipe(take(1)));

            if (authState === null) {
                return true;
            }

            const { accessToken } = authState.multiFactor.user;
            const decodeAccessToken: any = jwt_decode(accessToken);
            this.sessionStore.update({
                ...authState.multiFactor.user,
                ...{ accessToken: accessToken },
                ...{ userExtractedToken: decodeAccessToken },
            });

            const isTokenExpired = this.authService.isTokenExpired(decodeAccessToken?.exp);

            if (isTokenExpired) {
                const newToken = await lastValueFrom(this.authService.renewToken());
                this.sessionStore.updateToken(newToken);
                return newToken;
            } else {
                return true;
            }
        } catch (error) {
            // Handle any errors that might occur during the initialization process
            console.error('Error during app initialization:', error);
            return null; // Return a resolved promise with a value of null
        }
    }
}
