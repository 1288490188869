import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom } from 'rxjs';
import { Batch } from 'src/app/core/model/batch';
import { BatchService } from 'src/app/core/services/batch.service';
import { BatchErrorQuery } from 'src/app/core/state/batches/batch.errors.query';
import { BatchErrorStore } from 'src/app/core/state/batches/batch.errors.store';
import { TableColumns } from 'src/app/shared/components/dynamic-table/dynamic-table.component';
import { TrimTextPipe } from 'src/app/shared/pipes/trim.text.pipe';

@UntilDestroy()
@Component({
    selector: 'app-batch-error',
    templateUrl: './batch-error.component.html',
    styleUrls: ['./batch-error.component.scss'],
})
export class BatchErrorComponent implements OnInit {
    title: any = '';
    id: any = '';
    batchErrorLoading$ = this.batchErrorQuery.selectLoading();
    batchErrors$ = this.batchErrorQuery.selectAll();

    columnTable: TableColumns[] = [
        {
            field: 'errorType',
            header: 'Error Code',
            type: 'text',
            styleCss: 'width: 150px;',
        },
        {
            field: 'errorDescription',
            header: 'Description',
            type: 'text',
        },
        {
            field: 'errorTotal',
            header: 'Count',
            type: 'text',
            styleCss: 'width: 200px;',
        },
    ];

    constructor(
        private route: ActivatedRoute,
        private batchService: BatchService,
        private batchErrorStore: BatchErrorStore,
        private batchErrorQuery: BatchErrorQuery,
        private trimTextPipe: TrimTextPipe,
        private router: Router
    ) { }

    ngOnInit() {
        this.batchErrorStore.setLoading(true);
        this.route.params
            .pipe(untilDestroyed(this))
            .subscribe(async (params) => {
                const id = params['batchId'];
                const batchErrors: Batch | any = await lastValueFrom(
                    this.batchService.getBatch(id).pipe(untilDestroyed(this))
                );
                if (batchErrors.error) {
                    this.router.navigate(['/batch/overview']);
                } else {
                    if (batchErrors) {
                        batchErrors['totalErrors'] = batchErrors.serverErrors + batchErrors.validationErrors + batchErrors.processingErrors;
                    }
                    this.batchErrorStore.updateErrorBatchData(id, batchErrors);
                }
                this.title =
                    'Error in batch ' + this.trimTextPipe.transform(id);
                this.id = id;
                await lastValueFrom(
                    this.batchService.getBatchErrorTotal(id, batchErrors)
                        .pipe(untilDestroyed(this))
                );
                this.batchErrorStore.setLoading(false);
            });
    }

    async downloadErrorReport() {
        await lastValueFrom(this.batchService.downloadBatchError(this.id));
    }
}
