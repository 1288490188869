import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Batch } from '../../model/batch';

export interface BatchState extends EntityState<Batch> {
    isLoaded: false;
}
export function createInitialState(): BatchState {
    return {
        isLoaded: false,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'batch-customer', idKey: 'batchId' })
export class BatchStore extends EntityStore<BatchState> {
    constructor() {
        super(createInitialState());
    }

    loadBatch(batches: BatchState[] | any, isLoaded: boolean) {
        this.set(batches);
        this.update((state: any) => ({
            ...state,
            isLoaded,
        }));
    }

    updateLoaded(isLoaded: boolean) {
        this.update((state: any) => ({
            ...state,
            isLoaded,
        }));
    }

    updatePost(batch: BatchState | any) {
        this.update(batch.id, JSON.parse(JSON.stringify(batch)));
    }
}
