import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardFn } from './core/guard/auth.guard';
import { BatchGuardFn } from './core/guard/batch.guard';

const routes: Routes = [
    { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
    { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
    {
        path: 'auth',
        loadChildren: () =>
            import('./pages/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [AuthGuardFn],
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./pages/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
        canActivate: [BatchGuardFn],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)], // , {useHash: true})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
