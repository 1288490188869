import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DateHelper {
    /**
     * @param months pass number of months to be deducted on the current date
     */
    getMinusDateToday(months: number) {
        const minDate = new Date();
        minDate.setMonth(minDate.getMonth() - months);
        return minDate;
    }

    /**
     *
     * @param date pass date and convert it to date t string
     * @returns 2014-05-11
     */
    converDateToDateTString(date: Date) {
        return new Date(
            date.getTime() - date.getTimezoneOffset() * 60000
        ).toISOString();
    }
}
