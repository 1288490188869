<div class="download-template">
    <button pButton class="mt-11 p-element p-ripple p-button p-component" (click)="downloadTemplate()">
        <div class="icon-wrapper"><span class="icon icon-plus icon-download icon-invert"></span> </div>Download
        Template
    </button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="batchAddForm">
    <div class="row">
        <div class="col-md">
            <div class="title">Create a new Request</div>
            <p>
                Select your data service, fill in your details, upload your
                file and we'll get it running.
            </p>
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="login">Data Services</label>
                        <p-dropdown placeholder="Select" formControlName="batchType"
                            [options]="batchServiceData$ | async" optionLabel="name"></p-dropdown>
                    </span>
                </div>
                <div [hidden]="
                        batchAddForm.controls['batchType'].valid ||
                        !batchAddForm.controls['batchType'].dirty
                    " class="error-message"></div>
            </div>
            <ng-container *ngIf="batchAddForm && batchAddForm.controls['batchType'].value">
                <br />
                <div>Description</div>
                <p style="white-space: pre-line;">
                    {{
                    batchAddForm.controls['batchType'].value[
                    'description'
                    ]
                    }}
                </p>
                <br />
            </ng-container>
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="email">Email Address Notification</label>
                        <input type="email" id="email" pInputText formControlName="email"
                            placeholder="Enter your Email" />
                    </span>
                </div>
                <div [hidden]="
                        batchAddForm.controls['email'].valid ||
                        !batchAddForm.controls['email'].dirty
                    " class="error-message">
                    This email is not valid
                </div>
            </div>
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="userBatchReference">Your Reference</label>
                        <input type="email" id="userBatchReference" pInputText formControlName="userBatchReference"
                            placeholder="Enter your reference to identify this request" />
                    </span>
                </div>
                <div [hidden]="
                        batchAddForm.controls['userBatchReference'].valid ||
                        !batchAddForm.controls['userBatchReference'].dirty
                    " class="error-message">
                    This field is required
                </div>
            </div>
        </div>
        <div class="col-md">
            <br />
            <br />

            <p-fileUpload class="custom-upload-design" [class.hide-choose]="
                    !(uploadedFiles && uploadedFiles.length <= 0)
                " [class.loading]="isBatchCreateLoading" name="uploadedFiles[]" #pUpload [showUploadButton]="false"
                [showCancelButton]="false" #pFileUpload [chooseLabel]="'Browse for file'" [multiple]="false"
                accept=".csv" (onSelect)="onSelect($event)" [mode]="'advanced'" [maxFileSize]="500000000">
                <ng-template pTemplate="content">
                    <div class="upload-info container text-center"
                        *ngIf="!uploadedFiles.length && !isBatchCreateLoading">
                        <i class="icon icon-upload"></i>
                        <p>Drag and Drop files</p>
                        <p>Or</p>
                    </div>

                    <div class="upload-info container text-center" *ngIf="uploadedFiles.length && isBatchCreateLoading">
                        <p>Uploading file</p>
                        <br />
                        <br />
                        <div>
                            <p-progressBar class="progress-bar" mode="indeterminate" [showValue]="false"
                                [style]="{ height: '3px' }">
                            </p-progressBar>
                        </div>
                        <br />
                        <p class="plr-50">
                            File name: {{ uploadedFiles[0].name }}
                        </p>
                    </div>

                    <div class="upload-info-hasfile container text-center"
                        *ngIf="uploadedFiles.length && !isBatchCreateLoading">
                        <i class="pi pi-file"></i>
                        <p>Chosen file</p>
                        <p class="plr-50">
                            File name: {{ uploadedFiles[0].name }}
                        </p>
                        <div class="btn" (click)="removeFile()">
                            <i class="icon icon-remove"></i> Cancel file
                        </div>
                    </div>

                    <div class="file-info" *ngIf="!uploadedFiles.length && !isBatchCreateLoading">
                        <p style="margin-bottom: 0px;"> .CSV only</p>
                        <p style="margin-top: 0px;">Max file size is 500MB</p>
                    </div>
                </ng-template>
            </p-fileUpload>
            <div>
                <br /><br />
                <button pButton type="submit" class="mt-11 p-element w-100 p-ripple p-button p-component"
                    label="Add Request" [disabled]="!(batchAddForm.valid && uploadedFiles.length) || isBatchCreateLoading"></button>
                <br /><br />
                <button pButton type="button" (click)="navigateOverview()"
                    class="mt-11 p-element w-100 p-ripple p-button-outlined p-button p-component"
                    label="Cancel "></button>
            </div>
        </div>
    </div>
</form>