import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent {
    private _tableData: TableData | undefined;

    @Input()
    set tableData(tableData: TableData | undefined) {
        if (tableData) {
            this._tableData = tableData;
        }
    }

    get tableData() {
        return this._tableData;
    }

    @Input()
    isLoading: boolean | null | any = false;

    @Input()
    scrollHeight: string = 'flex';

    @Output()
    actionEvent: EventEmitter<TableActionEvent> =
        new EventEmitter<TableActionEvent>();


    onActionEvent(rowData: any, action: string) {
        this.actionEvent.emit({ rowData, action });
    }
}

export interface TableColumns {
    field: string;
    header: string;
    type?:
    | string
    | 'id'
    | 'action'
    | 'text'
    | 'date'
    | 'combine'
    | 'progress-bar'
    | 'errors';
    actionIcon?: string;
    action?: string;
    combineFields?: any[];
    styleCss?: string;
    styleClass?: string;
    disabledFromField?: DisabledFromField;
    hideFromField?: HideFromField;
    displayFieldValue?: any;
    hideIfValueField?: HideIfValueField;
}

export interface TableData {
    data: any;
    cols: TableColumns[];
}

export interface HideIfValueField {
    field?: any;
    value?: any;
    condition: any | 'greater' | 'lesser';
}
export interface TableActionEvent {
    rowData?: any;
    action: any;
}

export interface DisabledFromField {
    field?: any | string;
    values?: any[];
}

export interface HideFromField extends DisabledFromField { }
