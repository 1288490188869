import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BatchTypes } from '../../model/batch';

export interface BatchTypeState extends EntityState<BatchTypes> {
    isLoadedType: false;
}
export function createInitialState(): BatchTypeState {
    return {
        isLoadedType: false,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'batch-type', idKey: 'batchTypeCode' })
export class BatchTypesStore extends EntityStore<BatchTypeState> {
    constructor() {
        super(createInitialState());
    }

    loadBatchTypes(batchTypes: BatchTypes[] | any, isLoadedType: boolean) {
        this.set(batchTypes);
        this.update((state: any) => ({
            ...state,
            isLoadedType,
        }));
    }

    updateBatchType(batch: BatchTypes | any) {
        this.update(batch.id, JSON.parse(JSON.stringify(batch)));
    }
}
