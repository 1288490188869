import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LabelHelper {
    /**
     * @param code pass code country returns label
     */
    getLabelCountry(code: string) {
        const labels = [
            {
                label: 'Australia',
                code: 'AU',
            },
            {
                label: 'New Zealand',
                code: 'NZ',
            },
        ];
        const [finalLabel] = labels.filter(
            (data) => data.code.toLowerCase() == code.toLowerCase()
        );
        return finalLabel ? finalLabel.label : '';
    }
}
