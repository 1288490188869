import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-auth-component',
    templateUrl: './auth.component.html',
})
export class AuthComponent {
    isUnified: boolean = '' + environment.unified == 'true';
}
