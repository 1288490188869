<div class="title">{{ title }}</div>
<div class="add-batch">
    <button pButton class="mt-11 p-element p-ripple p-button p-component" (click)="goToAddBatch()">
        <div class="icon-wrapper"> <span class="icon icon-plus icon-invert"></span></div> Add a Request
    </button>
</div>
<div class="table-wrapper">
    <app-dynamic-table [tableData]="{ data: (batchData$ | async), cols: columnTable }"
        (actionEvent)="actionEvents($event)" [isLoading]="batchDataIsLoading$ | async"></app-dynamic-table>
</div>

<app-side-panel [isVisible]="isSidePanelVisible" (onHideEvent)="onHideEvent($event)">
    <ng-container *ngIf="currentSidePanel == 'cancel-panel'">
        <div class="action-wrapper">
            <div class="container">
                <div class="title">Cancel Batch</div>
                <p>Are you sure you want to stop this batch from running?</p>
                <div>
                    <br />
                    <button (click)="cancelBatch()" [disabled]="batchDataIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element btn-style p-ripple p-button p-component">
                        <span class="icon icon-remove icon-invert"> </span>
                        Cancel Batch
                    </button>

                    <button pButton (click)="onHideEvent(false)" [disabled]="batchDataIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element p-ripple p-button-outlined btn-style p-button p-component">
                        Exit
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</app-side-panel>