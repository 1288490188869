export const BATCH_STATUS = {
    FAILED: 'FAILED',
    PREPROCESSING: 'PRE_PROCESSING',
    VALIDATING: 'VALIDATING',
    SCHEDULED: 'SCHEDULED',
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
};

export const BATCH_STATUS_LABEL = {
    FAILED: 'FAILED',
    PREPROCESSING: 'PRE-PROCESSING',
    SCHEDULED: 'SCHEDULED',
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
    VALIDATING: 'PRE-PROCESSING',
};

export const BATCH_STATUS_LABEL_TOOLTIP = {
    FAILED: 'This vehicle batch encountered an error and was unable to start, please check the file format and try again.',
    PREPROCESSING: 'The system is preparing your vehicle batch for processing.',
    VALIDATING: 'The system is preparing your vehicle batch for processing.',
    SCHEDULED:
        'This vehicle batch has been scheduled to run at [Time], please wait for it to start.',
    SCHEDULED_START: 'This vehicle batch has been scheduled to run at ',
    SCHEDULED_END: ', please wait for it to start.',
    PROCESSING: 'The system is currently executing your vehicle batch.',
    COMPLETED:
        'This vehicle batch has finished, and the results are ready for viewing.',
    CANCELLED:
        'This vehicle batch has been cancelled and will not continue running.',
    EXPIRED: 'EXPIRED',
};

export const BATCH_ERROR_DESCRIPTION = {
    PROCESSING_ERROR:
        'The system was unable to complete the task due to a processing error. Please review the downloadable error CSV file for more information.',
    SERVER_ERROR:
        'The server encountered an unexpected error and could not complete your request.',
    VALIDATION_ERROR:
        'The provided input data was not valid. Please review the downloadable error CSV file for more information.',
};
