import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input()
    headerOptions: HeaderOptions = {
        title: 'Bulk Data Overview',
        icon: 'icon icon-menu',
    };

    @Input()
    hasSignOut?: boolean = false;

    @Input()
    backUrl: BackUrl | undefined = undefined;

    constructor(
        public authService: AuthService,
        private location: Location,
        private router: Router
    ) { }

    signOut() {
        this.authService.signOut();
    }

    goBackToUrl(url: any) {
        if (url) {
            this.router.navigate([url]);
        } else {
            this.location.back();
        }
    }
}

export interface HeaderOptions {
    title?: string;
    icon?: string;
}

export interface BackUrl {
    hasBackurl?: boolean;
    url?: string;
}
