import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BatchServiceState, BatchServiceStore } from './batch.services.store';

@Injectable({
    providedIn: 'root',
})
export class BatchServicesQuery extends QueryEntity<BatchServiceState> {
    selectBatchServiceIsLoaded$ = this.select((state) => {
        return state.isLoaded;
    });

    constructor(protected override store: BatchServiceStore) {
        super(store);
    }
}
