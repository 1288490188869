<ng-container *ngIf="!isUnified">
    <div class="container-fluid auth-wrapper">
        <div class="row">
            <div class="col-md">
                <router-outlet></router-outlet>
            </div>
            <div class="col-md col-md-6">
                <div class="bg-icon"></div>
            </div>
        </div>
    </div>

    <p-toast position="bottom-center" key="bc"></p-toast>
    <p-toast position="top-center" key="tc"></p-toast>
    <p-toast position="bottom-right" key="br"></p-toast>
    <p-toast position="bottom-left" key="bl"></p-toast>
</ng-container>