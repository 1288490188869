import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateOrEmpty',
})
export class DateOrEmptyPipe implements PipeTransform {
    transform(value: any): any {
        const date = new Date(value);
        if (isNaN(date.getTime())) {
            return '';
        }
        return value;
    }
}
