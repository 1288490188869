import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { FileHelper } from 'src/app/shared/helper/file.helper';
import { environment } from 'src/environments/environment';
import { Batch, BatchData, BatchError, BatchForm, Error } from '../model/batch';
import { BatchErrorStore } from '../state/batches/batch.errors.store';
import { BatchStore } from '../state/batches/batch.store';
import { saveAs } from 'file-saver';
import { BATCHES_URL } from '../constants/batch.urls.constant';
import { BATCH_ERROR_DESCRIPTION } from '../constants/batch.constant';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class BatchService {
    constructor(
        private http: HttpClient,
        private batchStore: BatchStore,
        private batchErrorStore: BatchErrorStore,
        private messageService: MessageService,
        private fileHelper: FileHelper
    ) {}

    /**
     *
     * @param date pass date for createdAfterDate
     * @returns list of batches
     */
    getBatches(date?: string): Observable<Batch[]> {
        const params = date ? '?createdAfterDate=' + date : '';
        return this.http
            .get<Batch[]>(`${environment.apiUrl}` + `${BATCHES_URL}` + params)
            .pipe(
                tap((batch: any) => {
                    if (batch) {
                        let { batches } = batch;
                        batches.sort((a: Batch, b: Batch) => {
                            return (
                                new Date(b.requestedDatetime).valueOf() -
                                new Date(a.requestedDatetime).valueOf()
                            );
                        });
                        batches = batches.map((data: Batch) => {
                            data['totalErrors'] =
                                data.processingErrors +
                                data.validationErrors +
                                data.serverErrors;
                            return data;
                        });
                        this.batchStore.loadBatch(batches, true);
                        return batches;
                    }
                })
            );
    }

    /**
     *
     * @param batcForm Currently having an error
     * @returns
     */
    createBatch(uuid: string, batcForm?: BatchForm): Observable<any> {
        const { file } = batcForm as any;
        const body = {
            ...batcForm,
        };

        return this.http
            .post<Batch[]>(`${environment.apiUrl}` + `${BATCHES_URL}`, body, {
                observe: 'response',
                headers: new HttpHeaders().set(
                    'Client-Request-Id',
                    uuid
                ),
            })
            .pipe(
                untilDestroyed(this),
                switchMap((res: any) => {
                    let headers = new HttpHeaders();
                    headers = headers.set('Content-Type', file.type);
                    let httpOptions: any = {
                        headers: headers,
                        observe: 'response',
                    };
                    const { url } = res.body;
                    return this.http.put<any>(`${url}`, file, httpOptions);
                }),
                catchError((e) => {
                    if (e.status != 409) {
                        this.messageService.add({
                            key: 'br',
                            severity: 'error',
                            summary: 'Error',
                            detail: e.error.message,
                            closable: false,
                        });
                    }
                    this.batchStore.setLoading(false);
                    return of(e);
                })
            );
    }

    /**
     *
     * @param batchId get invidividual batch via batch id
     * @returns
     */
    getBatch(batchId?: string): Observable<Batch> {
        return this.http
            .get<Batch>(
                `${environment.apiUrl}` + `${BATCHES_URL}` + `/${batchId}`
            )
            .pipe(
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message,
                        closable: false,
                    });
                    this.batchStore.setLoading(false);
                    return of(e);
                })
            );
    }

    /**
     *
     * @param batchId delete batch from the server
     * @returns
     */
    deleteBatch(batchId?: string): Observable<any> {
        return this.http
            .delete<any>(
                `${environment.apiUrl}` + `${BATCHES_URL}` + `/${batchId}`
            )
            .pipe(
                tap((res: any) => {
                    this.batchStore.remove(batchId);
                    this.messageService.add({
                        key: 'br',
                        severity: 'primary',
                        summary: 'Success',
                        detail: 'Batch successfully cancelled',
                        closable: false,
                    });
                    return res;
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message,
                        closable: false,
                    });
                    this.batchStore.setLoading(false);
                    return of(e);
                })
            );
    }

    /**
     *
     * @param batchId batchid to get batch error
     * @param batch add temporary data for handling error api
     * @returns
     */
    getBatchError(batchId: string, batch?: Batch): Observable<BatchError[]> {
        return this.http
            .get<BatchError[]>(
                `${environment.apiUrl}` +
                    `${BATCHES_URL}` +
                    `/${batchId}/errors`
            )
            .pipe(
                tap((batchError: any) => {
                    if (batchError) {
                        const { batchItemsErrors } = batchError;
                        this.batchErrorStore.loadBatchError(
                            batchItemsErrors,
                            true,
                            batchId,
                            batch
                        );
                        return batchError;
                    }
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.statusText,
                        closable: false,
                    });
                    return of(e);
                })
            );
    }

    /**
     *
     * @param batchId batchid to get batch error
     * @param batch add temporary data for handling error api
     * @returns
     */
    getBatchErrorTotal(
        batchId: string,
        batchErrors: Batch
    ): Observable<BatchError[]> {
        return this.http
            .get<BatchError[]>(
                `${environment.apiUrl}` +
                    `${BATCHES_URL}` +
                    `/${batchId}/errors/totals`
            )
            .pipe(
                tap((batchError: any) => {
                    if (batchError) {
                        const { batchErrorsTotals } = batchError;
                        batchError.errorTotal = batchErrorsTotals.reduce(
                            (data: any, value: any) => {
                                return data.errorType + value;
                            },
                            0
                        );
                        if (batchErrorsTotals) {
                            batchErrorsTotals.map((error: Error) => {
                                let desc = '';
                                switch (error.errorType) {
                                    case 'PROCESSING_ERROR':
                                        desc =
                                            BATCH_ERROR_DESCRIPTION.PROCESSING_ERROR;
                                        break;
                                    case 'SERVER_ERROR':
                                        desc =
                                            BATCH_ERROR_DESCRIPTION.SERVER_ERROR;
                                        break;
                                    case 'VALIDATION_ERROR':
                                        desc =
                                            BATCH_ERROR_DESCRIPTION.VALIDATION_ERROR;
                                        break;
                                    default:
                                        break;
                                }
                                error.errorDescription = desc;
                                return error;
                            });
                            this.batchErrorStore.loadBatchError(
                                { ...batchErrorsTotals },
                                true,
                                batchId,
                                batchErrors
                            );
                        }

                        return batchError;
                    }
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.statusText,
                        closable: false,
                    });
                    return of(e);
                })
            );
    }

    /**
     *
     * @param batchId download specific batch report
     * @returns download csv batch file
     */
    downloadBatchReport(batchId?: string): Observable<BatchData> {
        return this.http
            .get<BatchData>(
                `${environment.apiUrl}` +
                    `${BATCHES_URL}` +
                    `/${batchId}/reports`
            )
            .pipe(
                tap((res: BatchData) => {
                    if (res) {
                        this.fileHelper.downloadFile(res.url);
                    }
                    return res;
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message,
                        closable: false,
                    });
                    this.batchStore.setLoading(false);
                    return of(e);
                })
            );
    }

    /**
     *
     * @param batchId
     * @returns download batch error csv
     */
    downloadBatchError(batchId?: string): Observable<BatchData> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            }),
            responseType: 'arraybuffer' as any,
        };
        return this.http
            .get<BatchData>(
                `${environment.apiUrl}` +
                    `${BATCHES_URL}` +
                    `/${batchId}/errors/download`,
                httpOptions
            )
            .pipe(
                tap((res: any) => {
                    const blob = new Blob([res], { type: 'text/csv' });
                    saveAs.saveAs(blob, batchId + '_batch_error.csv');
                    return res;
                }),
                catchError((e) => {
                    this.messageService.add({
                        key: 'br',
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message,
                        closable: false,
                    });
                    this.batchStore.setLoading(false);
                    return of(e);
                })
            );
    }
}
