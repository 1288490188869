<div class="sidebar-wrapper">
    <div class="header">
        <app-logo></app-logo>
    </div>
    <div class="header-info" *ngIf="userInfo">
        <h3>{{ userInfo.groupName ? userInfo.groupName : userInfo.displayName }}</h3>
        <span>{{
            userInfo.groupId ? ( userInfo.groupId | trimText ) :
            (
            userInfo.userExtractedToken && userInfo.userExtractedToken.user_id
            ? (userInfo.userExtractedToken.user_id | trimText)
            : '' )
            }}</span>
    </div>
    <div class="menu">
        <ng-container *ngFor="let m of sideBarMenu">
            <div class="list" [class.active]="m.isActive" (click)="onClickNavigate(m.url)">
                <span [ngClass]="m.iconClass"></span>
                <span class="label">{{ m.label }} </span>
            </div>
        </ng-container>
    </div>
</div>